// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore, collection, addDoc} from "firebase/firestore";
import {getStorage, ref, uploadBytes} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDEsYvyCQYyc4UcY8QY-rrIylqunMEoERc",
  authDomain: "waitlist-email-a435c.firebaseapp.com",
  projectId: "waitlist-email-a435c",
  storageBucket: "waitlist-email-a435c.appspot.com",
  messagingSenderId: "1082506647075",
  appId: "1:1082506647075:web:77129562206308f09c0396",
  measurementId: "G-H7N6GN6Y7Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB= getFirestore(app);
const auth = getAuth(app);
const fireStorage= getStorage(app);

export { fireDB, auth, fireStorage, ref, addDoc, collection };