import React, { useState } from 'react';
import './LandingPage.css';
import { fireDB, addDoc, collection } from './firebase';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(fireDB, 'waitlist'), { email, phoneNumber });
      navigate('/thank-you');
      alert('Successfully joined the waitlist!');
      setEmail('');
      setPhoneNumber('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Failed to join the waitlist. Please try again.');
    }
  };

  return (

    
    <div className="landing-container">
      {/* <header className="landing-header"> */}
        <div className="logo"><img src='logo.png' alt=""/></div> 
      {/* </header> */}
      <div className="content">
        <h1>
          Print On Demand <span>exclusively for</span> fashion brands.
        </h1>
        {/* <p>Experience a custom-built Print On Demand platform built exclusively for fashion brands.</p> */}
        <form onSubmit={handleSubmit} className="waitlist-form">
          <input
            type="email"
            placeholder="you@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <button type="submit">Join Waitlist</button>
        </form>
      </div>
    </div>
  );
};

export default LandingPage;