// src/ThankYouPage.js
import React from 'react';
import './ThankYouPage.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';

const ThankYouPage = () => {
  return (
    <div className="thank-you-container">
      <h1>Successfully joined the waitlist!</h1>
      <Link to="/" className="back-to-home">Back to Home</Link>
    </div>
  );
};

export default ThankYouPage;
